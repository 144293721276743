const programCotegory = [

  {
    label: 'типовой тв проект',
    value: 'типовой тв проект',
  },

  {
    label: 'новый проект',
    value: 'новый проект',
  },

  {
    label: 'шаблонный тв проект',
    value: 'шаблонный тв проект',
  }

]

export default programCotegory