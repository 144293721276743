const operatorList = [

  {
    label: 'не определен',
    value: '',
    colorId: "#FC05E3"
  },

  {
    label: 'СТУДИЯ',
    value: '85252645',
    colorId: "#ff0000"
  },


  {
    label: 'Каюмов Ильдар',
    value: '245168122',
    colorId: "#0838E0"
  },

  {
    label: 'Нугуманов Роман',
    value: '208476624',
    colorId: "#FCC605"
  },

  {
    label: 'Федотов Виталий',
    value: '186546719',
    colorId: '#099806'
  },

  {
    label: 'Никишина Анастасия',
    value: '244643690',
    colorId: "#05FCED"
  },

  {
    label: 'Сагидуллин Айдар',
    value: '717358495',
    colorId: "#FC7C05"
  },

  {
    label: 'Яковлев Игорь',
    value: '486408353',
    colorId: "#FC7C05"
  },

  {
    label: 'Шуняев Андрей',
    value: '172933695',
    colorId: "#BCFC05"
  },

  {
    label: 'Роман Седов',
    value: '942533767',
    colorId: "#05FCFC"
  },

  {
    label: 'Емаев Сергей',
    value: '1267512293',
    colorId: "#05FCFC"
  },

  {
    label: 'Руслан Габидуллин',
    value: '901944117',
    colorId: "#fc0303"
  }
]

export default operatorList