const UiTechSvg = ({...props}) => {

  return(
    <svg width="60" height="80" viewBox="0 0 37 38" fill='currentColor' xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_45_172)">
    <path d="M8.24909 38C8.26009 37.1979 8.27708 36.3959 8.27109 35.5938C8.27109 35.5075 8.15513 35.3677 8.06916 35.3459C6.73163 34.994 5.69199 33.7656 5.71598 31.9602C5.74797 29.5866 5.72398 27.2121 5.72398 24.8376C5.72398 17.7151 5.72598 10.5926 5.72198 3.47007C5.72198 2.33189 6.06786 1.36028 7.01153 0.650408C7.60032 0.206242 8.27208 0.00597028 8.99983 0.00497883C15.3336 0.00101306 21.6674 -0.00592703 28.0012 0.00795316C29.9535 0.0119189 31.274 1.41481 31.275 3.4314C31.278 9.02016 31.276 14.6099 31.276 20.1987C31.276 24.1436 31.274 28.0876 31.277 32.0325C31.277 32.9982 31.0441 33.8875 30.2963 34.5429C29.9325 34.8611 29.4776 35.0941 29.0318 35.2954C28.7969 35.4015 28.7059 35.4897 28.7139 35.7405C28.7389 36.493 28.7409 37.2455 28.7519 37.999H24.8613V35.4966H12.1407V37.999H8.25009L8.24909 38ZM29.9985 17.7349C29.9985 12.9988 30.0004 8.2627 29.9945 3.52658C29.9945 3.23212 29.9795 2.92775 29.9035 2.64618C29.6626 1.75686 28.9168 1.26708 27.8502 1.26708C21.6164 1.26708 15.3826 1.26708 9.14878 1.26708C9.01182 1.26708 8.87387 1.26708 8.73792 1.28394C7.98319 1.3811 7.42338 1.76875 7.15548 2.47268C7.03252 2.79688 7.00753 3.17363 7.00753 3.52757C7.00053 12.9998 7.00153 22.4711 7.00153 31.9433C7.00153 32.0296 6.99953 32.1168 7.00253 32.2031C7.04752 33.4374 7.82724 34.2038 9.0738 34.2048C15.3576 34.2097 21.6414 34.2107 27.9252 34.1969C28.2741 34.1969 28.6549 34.1146 28.9658 33.9609C29.7595 33.5693 30.0014 32.8564 30.0004 32.0167C29.9945 27.2558 29.9975 22.4949 29.9975 17.7349H29.9985ZM9.58362 36.7171H10.8152V35.4927H9.58362V36.7171ZM27.4084 36.7171V35.4877H26.1808V36.7171H27.4084Z"/>
    <path d="M3.87464 27.1923C3.75168 27.4144 3.6937 27.5998 3.57174 27.7247C2.97595 28.3374 2.36817 28.9392 1.75438 29.5331C1.45049 29.8266 1.08162 29.8385 0.82071 29.5956C0.543807 29.3378 0.545807 28.963 0.857697 28.6448C1.45649 28.0341 2.06927 27.4382 2.67606 26.8364C2.89198 26.6222 3.16689 26.538 3.4228 26.6827C3.60473 26.7858 3.71769 27.0079 3.87464 27.1913V27.1923Z"/>
    <path d="M35.6325 15.8522C36.2033 15.8611 36.5042 16.3449 36.2872 16.7544C36.2143 16.8932 36.0943 17.0112 35.9823 17.1242C35.4395 17.6695 34.8967 18.2148 34.3429 18.7482C34.015 19.0645 33.6502 19.0764 33.3803 18.8077C33.1144 18.543 33.1303 18.1761 33.4472 17.8549C34.031 17.263 34.6198 16.6761 35.2206 16.101C35.3556 15.9722 35.5475 15.9018 35.6315 15.8512L35.6325 15.8522Z"/>
    <path d="M3.79364 18.2445C3.80963 18.7988 3.3308 19.117 2.91395 18.9237C2.81498 18.8781 2.72102 18.8097 2.64304 18.7323C2.05925 18.1603 1.47645 17.5872 0.902656 17.0052C0.565774 16.6642 0.54778 16.2993 0.831681 16.0267C1.09659 15.7729 1.47346 15.7947 1.79134 16.104C2.38513 16.683 2.97593 17.266 3.55572 17.8599C3.67868 17.9858 3.74666 18.1642 3.79464 18.2436L3.79364 18.2445Z"/>
    <path d="M36.3662 29.0165C36.3802 29.5628 35.8844 29.886 35.4765 29.6907C35.3776 29.6431 35.2846 29.5737 35.2066 29.4974C34.6238 28.9253 34.04 28.3523 33.4662 27.7713C33.1364 27.4372 33.1194 27.0594 33.4013 26.7937C33.6692 26.5409 34.04 26.5637 34.3599 26.875C34.9457 27.4451 35.5275 28.0192 36.0983 28.6041C36.2303 28.7389 36.3082 28.9253 36.3662 29.0175V29.0165Z"/>
    <path d="M1.92234 22.1746C2.3212 22.1746 2.72106 22.1647 3.11892 22.1776C3.53877 22.1905 3.80968 22.4482 3.80768 22.8052C3.80468 23.1422 3.54577 23.4208 3.14791 23.4298C2.3132 23.4496 1.4765 23.4506 0.641789 23.4288C0.222936 23.4179 -0.0199788 23.1294 0.00101385 22.7605C0.0210068 22.4086 0.26792 22.1885 0.686773 22.1776C1.09763 22.1667 1.50948 22.1756 1.92134 22.1756L1.92234 22.1746Z"/>
    <path d="M35.1107 22.1766C35.5225 22.1766 35.9344 22.1657 36.3453 22.1795C36.7391 22.1924 36.977 22.4155 36.999 22.7556C37.022 23.1145 36.7981 23.4089 36.4002 23.4208C35.5285 23.4466 34.6549 23.4456 33.7832 23.4178C33.4153 23.4059 33.1784 23.0976 33.1944 22.7734C33.2114 22.4393 33.4613 22.1944 33.8381 22.1815C34.262 22.1657 34.6858 22.1775 35.1097 22.1766H35.1107Z"/>
    <path d="M18.4875 32.9357C12.8415 32.8941 8.28811 28.3672 8.28711 22.7943C8.28611 17.2065 12.9245 12.627 18.5335 12.6766C24.1955 12.7272 28.7809 17.2977 28.7119 22.824C28.642 28.4574 24.0726 32.9764 18.4875 32.9357ZM18.4845 31.6687C23.3718 31.7044 27.3654 27.7624 27.4324 22.8359C27.4984 17.9967 23.5088 14.0002 18.5555 13.9437C13.6462 13.8872 9.62564 17.8341 9.56766 22.7685C9.51068 27.6226 13.5283 31.633 18.4845 31.6697V31.6687Z"/>
    <path d="M18.4595 11.4006C15.9684 11.347 13.9861 9.34135 14.0291 6.91628C14.0731 4.44759 16.1353 2.47066 18.5985 2.5351C21.0536 2.59955 23.0319 4.63597 22.9709 7.03724C22.9089 9.48908 20.8777 11.4521 18.4585 11.4006H18.4595ZM18.5045 10.1256C20.2539 10.1206 21.7004 8.68601 21.6914 6.96288C21.6824 5.23579 20.2269 3.79423 18.4965 3.79919C16.7601 3.80514 15.3096 5.24868 15.3096 6.97081C15.3096 8.69791 16.7601 10.1295 18.5045 10.1246V10.1256Z"/>
    <path d="M27.4634 3.76749V2.54901H28.6949V3.76749H27.4634Z"/>
    <path d="M8.31708 31.6945H9.54165V32.9119H8.31708V31.6945Z"/>
    <path d="M27.4634 32.9208V31.6895H28.6919V32.9208H27.4634Z"/>
    <path d="M9.54362 2.55298V3.77444H8.31305V2.55298H9.54362Z"/>
    <path d="M18.4865 30.3996C14.246 30.3679 10.8322 26.9652 10.8472 22.7843C10.8622 18.5677 14.32 15.1691 18.5545 15.2097C22.783 15.2504 26.1818 18.651 26.1548 22.8151C26.1278 27.0237 22.6841 30.4303 18.4865 30.3986V30.3996ZM18.4855 29.1306C21.9873 29.1553 24.8523 26.3307 24.8783 22.826C24.9043 19.343 22.0613 16.4946 18.5375 16.4738C15.0188 16.453 12.1688 19.2459 12.1248 22.7576C12.0818 26.2455 14.9348 29.1048 18.4855 29.1296V29.1306Z"/>
    <path d="M18.5105 8.85757C17.4579 8.86352 16.6062 8.03467 16.5902 6.98771C16.5742 5.94471 17.4589 5.06133 18.5095 5.07026C19.5541 5.07819 20.4098 5.93281 20.4118 6.96788C20.4138 8.00691 19.5641 8.85162 18.5105 8.85757ZM19.1243 6.96986C19.1243 6.63178 18.8584 6.35715 18.5205 6.34525C18.1686 6.33335 17.8777 6.61492 17.8777 6.96788C17.8777 7.3248 18.1576 7.59546 18.5185 7.58654C18.8624 7.57861 19.1243 7.31191 19.1243 6.96986Z"/>
    <path d="M18.5075 25.9679C16.7791 25.9768 15.3266 24.5412 15.3096 22.8082C15.2936 21.09 16.7411 19.6524 18.4935 19.6464C20.2409 19.6405 21.6804 21.0583 21.6904 22.7963C21.7004 24.5124 20.2479 25.959 18.5075 25.9689V25.9679ZM20.4098 22.8498C20.4518 21.8375 19.6301 20.9621 18.5965 20.9165C17.5169 20.8689 16.6502 21.6561 16.5932 22.7338C16.5392 23.7659 17.3929 24.6651 18.4605 24.7008C19.4862 24.7355 20.3659 23.8997 20.4088 22.8488L20.4098 22.8498Z"/>
    </g>
    <defs>
    <clipPath id="clip0_45_172">
    <rect width="37" height="38"/>
    </clipPath>
    </defs>
    </svg>

  )
}

export default UiTechSvg